import React, { Fragment,useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const Checkout = () => {


const [depositInput, setDepositInput] = useState({
  amount: '',
  password: '',
  error_list: [],
});
const handleInput = (e) => {
  e.persist();
  setDepositInput({...depositInput, [e.target.name]: e.target.value });
}

const [loading, setLoading] = useState(false);
const depositSubmit = (e) => {
  e.preventDefault();
  const auth_token = localStorage.getItem('auth_token');
  const data = {
      amount: depositInput.amount,
      password: depositInput.password,
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9'
  }
 if (auth_token !== null && !loading) {
  setLoading(true);
 axios.post(`/convert-balance`, data).then(res => {
  setLoading(false);
       if(res.data.status === 'success')
       {
         swal("Success",res.data.message,"success");
         setDepositInput({...depositInput,
           amount:'',
           password:'',
           
         });
       }
       else if(res.data.status === 'fail')
       {
         swal("Warning",res.data.message,"warning");
       }
       else 
       {
       setDepositInput({...depositInput, error_list: res.data.validation_error});
       }
       setLoading(false);
   });
 }
}

   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-4 order-md-2 mb-4">
                           <h4 className="d-flex justify-content-between align-items-center mb-3">
                              <span className="text-muted">Convert Balance</span>
                              <span className="badge badge-primary badge-pill">
                                $
                              </span>
                           </h4>
                           <ul className="list-group mb-3">
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Convert</h6>
                                    <small className="text-muted">
                                       Convert To Principal Wallet
                                    </small>
                                 </div>
                                 <span className="text-muted">100%</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Convert</h6>
                                    <small className="text-muted">
                                       Convert payment
                                    </small>
                                 </div>
                                 <span className="text-muted">${depositInput.amount - depositInput.amount * 5 / 100 }</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <small className="text-muted">
                                       Convert payment
                                    </small>                                 
                                    <h6 className="my-0">Convert</h6>

                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div className="col-md-8 order-md-1">
                           <h4 className="mb-3">Convert Main Wallet to Principal Wallet </h4>
                           <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                              <div className="row">
                                 <div className="col-md-6 mb-3">
                                    <label htmlFor="lastName">Amount</label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="Amount"
                                       id="lastName"required
                                       name="amount"
                                       onChange={handleInput} 
                                       value={depositInput.amount}
                                    />
                                       <span className="text-danger">{depositInput.error_list.amount}</span>
                                 </div>

                                 <div className="col-md-6 mb-3">
                                    <label htmlFor="firstName">
                                       Account Password
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       id="firstName"
                                       placeholder="Password"
                                       required
                                       name="password"
                                       onChange={handleInput} 
                                       value={depositInput.password}
                                    />
                                       <span className="text-danger">{depositInput.error_list.password}</span>
                                    
                                 </div>
                              </div>

                              <hr className="mb-4" />
                              <div className="form-check custom-checkbox mb-2">
                                 <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="same-address"
                                    required

                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="same-address"
                                 >
                                    Accept treams & condition
                                 </label>
                              </div>
                              <hr className="mb-4" />
                              <button
                                 className="btn btn-warning btn-lg btn-block"
                                 type="submit"
                                 disabled={loading}
                              >
                              {loading ? 'Submitting...' : 'Convert Continue'}
                              </button>

                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
