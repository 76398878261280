import React, { Fragment, useState, useReducer } from "react";
import { Button, Dropdown, Modal, Tab, Nav } from "react-bootstrap";
import { GlobalSet } from '../../global/GlobalProvider';
import { Link ,useHistory} from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import axios from "axios";
import { useEffect } from 'react';
//** Import Image */
import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const initialState = false;
const reducer = (state, action) =>{
	switch (action.type){
		case 'sendMessage':
			return { ...state, sendMessage: !state.sendMessage }		
		case 'postModal':
			return { ...state, post: !state.post }
		case 'linkModal':
			return { ...state, link: !state.link }		
		case 'cameraModal':
			return { ...state, camera: !state.camera }		
		case 'replyModal':
			return { ...state, reply: !state.reply }
		default:
			return state	
	}	
}


  const AppProfile = () => {
  const { CurrencySymbol } = GlobalSet();
  const { t } = useTranslation();
  const [user, setUser] = useState([]);
    useEffect(()=>{
    	const auth_token = localStorage.getItem('auth_token');
    	const selectedCurrency = localStorage.getItem('selectedCurrency');
        const data = {
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
            currency: selectedCurrency,
        }
    	if (auth_token !== null) {
    		 axios.post('/apps-profile',data)
       	 .then(response=>{
           setUser(response.data)
           console.log(response.data)
           
			     })
			     .catch(error=>{
			       console.log(error)
			   })
    	}

    },[])

	let Email;
	let mobile_No;
	let address;
	let name;
	let last_name;
	let reference_code;
	let balance;
	let bonus_balance;
	let total_deposit;
	let total_Income;
	let total_withdraw;
	let affiliate_earning;
	if (user == "") {
		console.log(user);
	}else{

		Email=user.data.email;
		name=user.data.name;
		last_name=user.data.last_name;
		mobile_No=user.data.mobile_No;
		address=user.data.address;
		reference_code=user.data.reference_code;
		balance=user.data.balance;
		bonus_balance=user.data.bonus_balance;
		total_deposit=user.data.total_deposit;
		total_Income=user.data.total_Income;
		total_withdraw=user.data.total_withdraw;
		affiliate_earning=user.data.affiliate_earning;

	}
		
	const options = {
	  settings: {
	    overlayColor: "#000000",
	  },
	};

	const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Fragment>
      <PageTitle activeMenu={t('pro_pg_slg_b')} motherMenu={t('pro_pg_slg_a')} />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="mb-0">{Email}</h4>
                    <p>{t('pro_pg_username')}</p>
                  </div>

                  <div className="profile-name px-3 pt-2">
                    <h4 className="mb-0">{reference_code}</h4>
                    <p>{t('pro_pg_ref_cd')}</p>
                  </div>


                  <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

	<div className="col-xl-12">
		<div className="row">
			<div className="col-xl-3 col-sm-6">
				<div className="card booking">
					<div className="card-body">
						<div className="booking-status d-flex align-items-center">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#f5a623" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
															</span>
							<div className="ms-4">
								<h4 className="mb-0 font-w600">{"$"+ balance}</h4>
								<p className="mb-0 text-nowrap">{t('pro_pg_curr_bal')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-sm-6">
				<div className="card booking">
					<div className="card-body">
						<div className="booking-status d-flex align-items-center">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#f5a623" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M3 3h18v18H3zM12 8v8m-4-4h8"/></svg>
							</span>
							<div className="ms-4">
								<h4 className="mb-0 font-w600">{"$"+ total_deposit}</h4>
								<p className="mb-0 text-nowrap ">{t('pro_pg_tot_dep')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-sm-6">
				<div className="card booking">
					<div className="card-body">
						<div className="booking-status d-flex align-items-center">
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#f5a623" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="18" height="18" rx="2"/><path d="M3 9h18M15 21V9"/></svg>
							</span>
							<div className="ms-4">
								<h4 className="mb-0 font-w600">{"$"+ total_Income}</h4>
								<p className="mb-0">{t('pro_pg_tot_inc')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-sm-6">
				<div className="card booking">
					<div className="card-body">
						<div className="booking-status d-flex align-items-center">
							<span>
							<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="#f5a623" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3"/></svg>

							</span>
							<div className="ms-4">
								<h4 className="mb-0 font-w600">{"$"+ total_withdraw}</h4>
								<p className="mb-0">{t('pro_pg_tot_wit')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

     <div className="row">
        <div className="col-xl-4">

		</div>	
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
					<Tab.Container defaultActiveKey='About'>					
						<Nav as='ul' className="nav nav-tabs mb-4">
							<Nav.Item as='li'i className="nav-item">
								<Nav.Link to="#about-me"  eventKey='About'>{t('pro_pg_ab_me')}</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>

							<Tab.Pane id="about-me" eventKey='About'>
								<div className="profile-personal-info">
									<h4 className="text-primary mb-4">
										Personal Information
									</h4>
									<div className="row mb-2">
										<div className="col-4">
											<h5 className="f-w-500"> {t('pro_pg_name')}:</h5>
										</div>
										<div className="col-8">
											<span>{name}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-4">
											<h5 className="f-w-500"> Last Name:</h5>
										</div>
										<div className="col-8">
											<span>{last_name}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-4">
											<h5 className="f-w-500">{t('pro_pg_email')}:</h5>
										</div>
										<div className="col-8">
											<span>{Email}</span>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-4">
											<h5 className="f-w-500">  {t('pro_pg_location')}:</h5>
										</div>
										<div className="col-8">
											<span>{address}</span>
										</div>
									</div>
								</div>
							</Tab.Pane>
						</Tab.Content>	
					</Tab.Container>		
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </Fragment>
  );
};

export default AppProfile;
