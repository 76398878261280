import React,{Fragment,useContext, useEffect,useState, useRef } from 'react';
import {Link} from 'react-router-dom';
import { useParams } from "react-router-dom";
import axios from "axios";
import { Row, Col, Card, Carousel, Accordion, ButtonGroup, Button } from 'react-bootstrap'
import swal from "sweetalert";


const Home = () => {
   const [OnHomeGame, OneGame] = useState([]); 

  // ifram full screen
  const videoRef = useRef(null);

  const handleToggleFullScreen = () => {
    const video = videoRef.current;

    if (video) {
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
      } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
      }
    }
  }; 
  // ifram full screen end



  useEffect(() => {
    const loadTradingViewWidget = async () => {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/tv.js';
      script.async = true;
      script.onload = () => {
        new window.TradingView.widget({
          width: 'auto',
          height: 450,
          symbol: 'FX:EURUSD',
          interval: '5',
          timezone: 'Etc/UTC',
          theme: 'dark',
          style: '1',
          locale: 'en',
          toolbar_bg: '#f1f3f6',
          enable_publishing: false,
          hide_top_toolbar: true,
          hide_legend: true,
          save_image: false,
          container_id: 'cryptofeture_map'
        });
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    loadTradingViewWidget();
  }, []);


return(
<>
   <span style={{  marginTop: '-36px' }} className="d-xl-block">
      <div className="row">

        <div className="card p-0 mb-0" style={{ width: '100vw', height: '60vh', position: 'relative' }}>
          
       

              <Col xl="12" className="p-0" >
               <div className="App">
                  <div className="table-responsive card booking">
                        <table
                          className="display w-100 dataTable "
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                           style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis', background: '#2f2f2f'}}
                        >
                          <tbody>
                            <tr className="odd" role="row">
                              <td> <Link to={"/currency-eur"} className="text-black">EUR/USD ( Euro  ) </Link></td>
                              <td> <Link to={"/currency-xau"} className="text-black">XAU/USD ( Gold   ) </Link></td>
                              <td> <Link to={"/currency-gbp"} className="text-black">GBP/USD ( Great Britain  ) </Link></td>
                              <td> <Link to={"/currency-btc"} className="text-black">BTC/USD ( Euro  ) </Link></td>
                              <td> <Link to={"/currency-eth"} className="text-black">ETH/USD ( Ethereum  ) </Link></td>
                              <td> <Link to={"/currency-bnb"} className="text-black">BNB/USD ( BNB  ) </Link></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                </div>
              </Col>

        <Col xl="12">
          <ButtonGroup style={{ float : "right"}}>
            <Button onClick={handleToggleFullScreen} variant="primary"><span className="fa fa-expand"></span></Button>
          </ButtonGroup>
        </Col>


              <Col xl="12" className="p-0" >
                    <div className="form-inline">

                        <div id="cryptofeture_map">
                          <div
                            id="tradingview_c98bb-wrapper"
                            style={{
                              position: 'relative',
                              boxSizing: 'content-box',
                              width: 'auto',
                              height: 450,
                              margin: '0 auto !important',
                              padding: '0 !important',
                              fontFamily:
                                "-apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif"
                            }}
                          >
                            <div
                              style={{
                                width: 'auto',
                                height: 450,
                                background: 'transparent',
                                padding: '0 !important'
                              }}
                            >
                              <iframe
                                id="tradingview_c98bb"
                                src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_c98bb&amp;symbol=FX%3AEURUSD&amp;interval=5&amp;hidetoptoolbar=1&amp;hidelegend=1&amp;saveimage=0&amp;toolbarbg=f1f3f6&amp;studies=%5B%5D&amp;theme=dark&amp;style=1&amp;timezone=Etc%2FUTC&amp;studies_overrides=%7B%7D&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=en&amp;utm_source=cryptofeture.com&amp;utm_medium=widget&amp;utm_campaign=chart&amp;utm_term=BYBIT%3A"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  margin: '0 !important',
                                  padding: '0 !important'
                                }}
                                frameBorder="0"
                                allowtransparency="true"
                                scrolling="no"
                                allowFullScreen=""
                              ></iframe>
                            </div>
                          </div>
                        </div>

                    </div>
              </Col>





        </div>

      </div>
    </span>
    </>
  )
}
export default Home;