import { Card, Col, ListGroup, Row } from "react-bootstrap";
import axios from "axios";
import vs from '../../images/profile/payment.png';
import {Link} from 'react-router-dom';
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Footer = () => {
  const { t } = useTranslation();
  var d = new Date();
  return (
    <div className="footer m-4 text-black">
        <div>
          <h4>{t('pro_footer_o_s_b')}</h4> 
           <p>{t('pro_footer_o_s_b_text_a')}</p>
           <p>{t('pro_footer_o_s_b_text_b')}</p>
        </div> 


      <div style={{textAlign:'center'}} className="copyright">
      <img src={vs} alt="" className=" me-3 card-list-img w-100" style={{ height: 200 }} />
       
      <Row>
        <Col  className="col-xl-6 col-xxl-6 col-sm-6">
            <Card.Header>
              <Card.Title>{t('pro_footer_information')}</Card.Title>
            </Card.Header>
            <Card.Body>

            <Row>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group">
                    <li as="ul">
                        <Link to={"/view-page/1/about-us"}>
                         <li className="mb-2 text-black"> {t('pro_footer_about_us')}</li>
                        </Link>
                        <Link to={"/view-page/2/Terms-and-conditions"}>
                         <li className="mb-2 text-black"> {t('pro_footer_trms_con')}</li>
                        </Link>
                        <Link to={"/view-page/3/privacy-policy"}>
                         <li className="mb-2 text-black"> {t('pro_footer_pr_po')}</li>
                        </Link>
                        <Link to={"/view-page/4/responsible-gaming"}>
                         <li className="mb-2 text-black"> {t('pro_footer_pospons_game')}</li>
                        </Link>
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <li className="mb-2"> {t('pro_footer_lv_gm')} </li>
                        <li className="mb-2"> {t('pro_footer_foo_bl')} </li>
                        <li className="mb-2"> {t('pro_footer_bask_bl')} </li>
                    </li>
                  </div>
              </Col>
            </Row>
            </Card.Body>
        </Col>
        <Col  className="col-xl-6 col-xxl-6 col-sm-6">
            <Card.Header>
              <Card.Title>{t('pro_footer_sports')} </Card.Title>
            </Card.Header>
            <Card.Body>

            <Row>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <li className="mb-2"> {t('pro_footer_casino')}  </li>
                        <li className="mb-2"> {t('pro_footer_lv_cas')} </li>
                        <li className="mb-2"> {t('pro_footer_tv_gm')} </li>
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <li className="mb-2"> {t('pro_footer_bt_res')} </li>
                        <li className="mb-2">  {t('pro_footer_bt_info')} </li>
                        <li className="mb-2">{t('pro_footer_res_gm')} </li>
                    </li>
                  </div>
              </Col>
            </Row>


            </Card.Body>
        </Col>
      </Row>

        <p>
          {t('pro_footer_copyright')}{" "}
          {d.getFullYear()}
        </p>
      </div>

        <footer className="mobilefutshow">
            <div className="footer-nav-wrapper">

                <Link className="footer-nav-single" to={"/"}>
                    <div className="menu-wrapper">
                        <i class="fa fa-home mb-1" style={{ color: "white", fontSize : "18px" }}></i>
                        <span className="nav-text">Home</span>
                    </div>
                </Link>

                <Link className="footer-nav-single" to={"/trading"}>
                    <div className="menu-wrapper">
                        <i class="fa fa-signal mb-1" style={{ color: "white", fontSize : "18px" }}></i>
                        <span className="nav-text">Trading</span>
                    </div>
                </Link>
                <Link className="footer-nav-single" to={"/deposit"}>
                    <div className="menu-wrapper">
                        <i class="fas fa-arrows-alt-v fa-fw mb-1" style={{ color: "white", fontSize : "18px" }}></i>
                        <span className="nav-text">Deposit</span>
                    </div>
                </Link>

              <Link className="footer-nav-single" to={"/app-profile"}>
                  <div className="menu-wrapper">
                      <i class="fa fa-user-circle mb-1" style={{ color: "white", fontSize : "18px" }}></i>
                      <span className="nav-text">Profile</span>
                  </div>
              </Link>

            </div>
        </footer>


    </div>
  );
};

export default Footer;
